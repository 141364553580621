import React from 'react'
import BGND from '../Assets/BG-.png'
import '../styles/Battlegnd.css'

const Battlegnd = () => {
  return (
    <div className='battlegnd-page-container'>
        <h1 className='bg-heading'>BATTLEGROUND</h1>
        <div className='bg-description'>
            Web3 play-to-earn model is installed in the app. Participating in the arena games awards the user with VYNECOIN (VYN). There are four (4) modes of gameplay in the arena:
            <div><span>Practice: </span>This is a campaign mode in the app that requires no entry fee. Here, the user is rewarded with VYN for participating while also learning how to play.</div>
            <div><span>Sparring: </span>In this mode, the user sends an invitation to a friend for a sparring match. Both users are rewarded for participation. </div>
            <div><span>Duel: </span>This is a competitive mode where both users stake either VYNECOIN (VYNE duel) or USDT (USDT duel) and the winner gets all. It should be of note that there will be an arena fee charged on both users per duel.</div>
        </div>
        <img src={BGND} alt='mininggraph'/>
    </div>
  )
}

export default Battlegnd