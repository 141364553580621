import React from "react"
import "../styles/Resources.css"

function Cotton() {

    return(
        <div className="resource-container">
            <div>
                <div className="resource-heading">
                    <p>#</p>
                    <p>User</p>
                    <p>QTY</p>
                    <p>Price</p>
                </div>
            </div>
        </div>
    )
}

export default Cotton