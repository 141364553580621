import React from 'react'
import "../styles/Wallet.css"

const Wallet = () => {
  return (
    <div className='wallet-page-container'>
        <h1 className='wallet-heading'>WALLET</h1>
        <div className='wallet-description'>
            The wallet in the VYNECHAIN mining application holds all assets gotten by users in the VYNE ecosystem.
            These assets are all testnet assets which would be subjected to swap ratio when the mainnet goes live in Q4 2025.
            These assets could be traded by users in the VYNECHAIN p2p market from Q1 or Q2 2025. The swap ratio
            will have no effect on the price of the asset after swap. The swap ratio estimate will be explained below but this is an estimated example as the actual ratio will be announced when the mainnet is launched.
        </div>
        <h2 className='wallet-subheading'>USD ILLUSTRATED VALUE</h2>
        <div className='wallet-description'>
            The current USD equivalent displayed is based on the revenue sharing estimate:
            <div className='wallet-description'>500,000 users</div>
            <div className='wallet-description'>AUMR - 30% (team, maintenance, upgrade)</div>
            <div className='wallet-description'>70% AUMR / TAUAC</div>
            <div className='wallet-description'>AUMR (Active Users Monthly Revenue)</div>
            <div className='wallet-description'>TAUAC (Total Active Users Accumulated Coin)</div>
            <div className='wallet-description'>The estimate on the app is purely a speculation and should not be used as the actual price of the coin, but as a guide, as the price could be higher or lower due to other factors ie partnership, user attached value e.t.c.</div>
            <div className='wallet-description'>VYNECOIN is structured to be a community driven coin. The mechanism of distribution is only by mining and farming in the VYNECHAIN app. There will be:
            <div>NO seed round</div>
            <div>NO presale</div>
            <div>NO ICO</div>
            <div>NO IDO</div>
            <div>NO public sale</div>
            <div>NO private sale</div>
            </div>
            <div className='wallet-description'>VYNECHAIN value will be determined before mainnet by the community. The value will be dependent on what the community deems is worth the time and effort that was put into accumulation of the assets.</div>
        </div>
        <h2 className='wallet-subheading'>ASSETS</h2>
        <div className='wallet-description'>
        <div className='leaderboard-description'><span>VYNE: </span>This is the testnet coin of the ecosystem, and can be divided into: MINED VYNE and FARMED VYNE. The swap ratio of each asset will vary with respect to the work done to acquire each asset.</div>
        <div className='leaderboard-description'><span>*MINED VYNE: </span>This is acquired by running the node in the app. This node gives a definite amount of VYNE and can be claimed at intervals of at least one hour. The VYNE can be accumulated for up to 6 hours after which the node storage will get full and can no longer mine. The user will then be required to claim the VYNE to empty the storage, and restart the node. This generates no income for the ecosystem as of the time of this publication.
        </div>
        <div className='leaderboard-description'><span>*FARMED VYNE: </span>This is gotten by planting VYNSEEDS (VSD) in the garden. This garden is to be nurtured everyday for 10 days in order to harvest (farmed) VYNE. This will greatly contribute to the development of the ecosystem as ADs are incorporated to reward the user with watering cans and fertilizer to nurture the farm and increase the farm yield.</div>
        <div className='leaderboard-description'><span>VYNSEED: </span>This is an anchor token of the VYNECHAIN ecosystem. This rewards system was designed to keep the ecosystem fair, and to reward active users for their work. VSD will be awarded for completing daily missions, tasks and referrals. The VSD can be planted and grown into (farmed) VYNE in the garden.</div>
        </div>
    </div>
  )
}

export default Wallet