import React from 'react'
import '../styles/Betatesting.css'

const Betatesting = () => {
  return (
    <div className='beta-testing-page-container'>
      <h1 className='beta-testing-page-heading'>BETA TESTING PROGRAM</h1>
      <div className='beta-testing-description'>This is designed to reward early adopters of VYNECHAIN ecosystem, giving them an edge in the network. The test program commences Q4 2024, and is expected to last a total of 10 days.
        <div className='beta-testing-description-child'>Registeration starts 3 days before the testing program commences and ends 2 days after the program begins.</div>
        <div className='beta-testing-description-child'>Ensure to follow official anouncement channels for more information.</div>
      </div>
      <h1 className='beta-testing-page-heading'>BETA TESTING REFERRAL CAMPAIGN</h1>
      <div className='beta-testing-description'>In order to foster community development, we are running a campaign to incentisize the user to refer friends and family into the VYNECHAIN network. The campagin details are shows in the images below: </div>
      <h2 className='beta-testing-subheading'>TERMS AND CONDITIONS:</h2>
      <div className='beta-testing-description'>1. Mininum withdrawal payout is $4.00.</div>
      <div className='beta-testing-description'>2. Maximum bonus accumulation is capped at $4,000.00.</div>
      <div className='beta-testing-description'>3. Withdrawals are only payable in the BSC chain (USD).</div>
      <div className='beta-testing-description'>4. There is a 10% fee on each withdrawal.</div>
      <div className='beta-testing-description'>5. Payout will be done between 10th to 20th of November, 2024.</div>
      <div className='beta-testing-description'>6. Bonus gotten from referrals at less than 2 POW (proof of work) will not be paid.</div>
      <h2 className='beta-testing-subheading'>REQUIREMENTS</h2>
      <div className='beta-testing-description'>1. Sign up on the offical website or app.</div>
      <div className='beta-testing-description'>2. Follow all social media channels.</div>
      <div className='beta-testing-description'>3. Get a mininum of 5 POW during the beta testing period.</div>
      <h2 className='beta-testing-subheading'>BETA TESTING ACTIVITIES</h2>
      <div className='beta-testing-description'>1. All beta testers must complete daily missions to acquire POW, as minimum requirement to be eligible for beta testing reward is 5 POW.</div>
      <div className='beta-testing-description'>2. Daily missions comprises of all the VYNECHAIN ecosystem activities.</div>
      <h2 className='beta-testing-subheading'>REWARDS</h2>
      <div className='beta-testing-description'>1. Mined VYN during the beta testing will be multipled by the number of POW attained.</div>
      <div className='beta-testing-description'>2. Farmed VYN durig the beta testing period will be multipled by 1/2 of the number of POW attained.</div>
      <div className='beta-testing-description'>3. Unique NFT avatars will be rewarded to Lite testers (users with at least 5 POW).</div>
      <div className='beta-testing-description'>4. Unique NFT avatars will be rewarded to Pro testers (users with at least 8 POW).</div>
      <h2 className='beta-testing-subheading'>BENEFITS</h2>
      <div className='beta-testing-description'>1. Be among the first set of whales in the VYNECHAIN network.</div>
      <div className='beta-testing-description'>2. Priviledge to be among the pace-setters in the community.</div>
      <div className='beta-testing-description'>3. Unique NFT avatar awarded to stand out among other users.</div>
      <div className='beta-testing-description'>4. USDT reward for referrals.</div>
    </div>
  )
}

export default Betatesting