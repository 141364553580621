import { useState, useEffect } from "react"
import "../styles/Resources.css"
import api from "../components/api"
import WoodItem from "./WoodItem"
import WoodOrder from "./WoodOrder"

function Woods() {
    const [woods, setWoods] = useState([]);
    const [openModal, setOpenModal] = useState(false)
    const [selectedWood, setSelectedWood] = useState(null)

    useEffect(() => {
        getWoods()
    }, [])

    const handleWoodClick = (wood) => {
        setSelectedWood(wood);
        setOpenModal(true);
    }

    const getWoods = () => {
        api
        .get("/api/woods/")
        .then((res) => res.data)
        .then((data) => {setWoods(data); console.log(data)})
        .catch((err) => alert(err));
    }


    return(
        <div>
            <div className="resource-container">
                <div className="resource-heading">
                    <p>#</p>
                    <p>User</p>
                    <p>QTY</p>
                    <p>Price</p>
                </div>
                {woods.map((wood) => <WoodItem
                key={wood.id}
                wood={wood}
                handleClick={() => handleWoodClick(wood)}
                />)}
                {selectedWood && <WoodOrder open={openModal} wood={selectedWood} onClose={() => setOpenModal(false)}/>}
            </div>
        </div>
    )
}

export default Woods