import React from 'react'
import Farmmech from '../Assets/Graph 2.png'
import "../styles/Farming.css"

const Farming = () => {
  return (
    <div className='farming-page-container'>
        <h1 className='subheadings'>FARMING</h1>
        <div className='vynechain-description'>
            The farming mechanism is designed to yield the most profit for the user in the VYNE app. The farm page consists of three gardens which will be unlocked at different levels. Farms at level one are designed to consume 1,500 VSD (VyneSeed) to give an output of 0.065–0.075VYN per day for 10 days, which ends every farming cycle. Tending to these gardens daily, with use of watering can and fertilizer, increases the user’s daily profit by 200 – 250% (at random) per tool. Watering can and fertilizer can either be bought or awarded by completing tasks or watching ads. These ads offer a good monetization strategy for the value of the ecosystem. 
        </div>
        <img src={Farmmech} alt='farminggraph'/>
    </div>
  )
}

export default Farming