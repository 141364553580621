import React from "react";
import "../styles/Resources.css"

function WoodItem(props){

    return (
        <div>
            <div>
                <div className="wood-row" onClick={props.handleClick}>
                    <p>{props.wood.id}</p>
                    <p>{props.wood.created_by.toUpperCase()}</p>
                    <p>{props.wood.quantity}</p>
                    <p>${props.wood.price}</p>
                </div>
            </div>
        </div>
    )
}

export default WoodItem