import React from 'react'

function NotFound() {
    return (
        <div className='container'>
            <h1>Error 404: Page NotFound</h1>
            <p>The Page you are looking for does not exist</p>
        </div>
    )
}

export default NotFound