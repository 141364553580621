import { useState } from "react"
import api from "../components/api"
import { useNavigate } from "react-router-dom"
import {ACCESS_TOKEN, REFRESH_TOKEN } from "../constants"
import "../styles/Form.css"
import FormInput from "../components/FormInput"
const swal = require('sweetalert2')

function Login() {
    const [errors, setErrors] = useState([])
    const [loading, setLoading] = useState(false)
    const [values, setValues] = useState({
        username: "",
        password: "",
    })
    const navigate = useNavigate()

    const inputs = [
        {
            id: 1,
            name: "username",
            type: "text",
            placeholder: "Username",
            errorMessage: "Username should be 5-16 characters without any special character!!",
            label: "Username",
            pattern: "^[A-Za-z0-9]{5,16}$",
            required: true
        },
        {
            id: 2,
            name: "password",
            type: "password",
            placeholder: "Password",
            errorMessage: "Password should be 8-20 characters with atleast 1 number and 1 special character!!",
            label: "Password",
            pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
            required: true
        },
    ]

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const data = new FormData(e.target);

        try {
            const response = await api.post("/v1/token/", [data.get['username'], data.get['password']])
            if (response) {
                localStorage.setItem(ACCESS_TOKEN, response.data.access);
                localStorage.setItem(REFRESH_TOKEN, response.data.refresh);
                swal.fire({
                    title: "Login Success",
                    icon: "success",
                    toast:  true,
                    position: 'top-right',
                    timerProgressBar: true,
                    showConfirmButton: true,
                })
                navigate("/")
            }else {
                navigate("/login")
            }


        }catch(errors) {
            setErrors(errors.response.data);
            console.log(errors.response.data);
        }finally {
            setLoading(false)
        }
    };
    
    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <div className="form-page-container">
            <form onSubmit={handleSubmit}>
                <h1>Login</h1>
                {inputs.map((input) => (
                    <FormInput
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                    />
                ))}
                <button>Login</button>
            </form>
        </div>
)
}

export default Login