import React from 'react'
import "../styles/Leaderboard.css"
import table1 from '../Assets/1.png'
import table2 from '../Assets/2.png'
import table3 from '../Assets/3.png'
import table4 from '../Assets/4.png'
import table5 from '../Assets/5.png'

const Leaderboard = () => {
  return (
    <div className='leaderboard-page-container'>
      <h1 className='leaderboard-heading'>TOURNAMENTS AND REWARDS</h1>
      <div className='leaderboard-description'>
        The VYNE app will have a total of four (4) leaderboards. These leaderboards will track and reward users on different activities performed, as well as other contributions to the ecosystem. Each leaderboard will consist of leagues that users can join to earn even more rewards. These leagues categorise the users according to the frequency of engagement with the activity corresponding to each leaderboard.
      </div>
      <h2 className='leaderboard-subheading'>ENGAGEMENT</h2>
      <div className='leaderboard-act-description'>
        <p>1. Activity Point</p>
        <p>2. Referral </p>
        <p>3. VYNE Duel</p>
        <p>4. USDT Duel</p>
      </div>
      <h1 className='leaderboard-subheading'>LEAGUES ON EACH LEADERBOARD</h1>
      <img src={table5} alt='leaguetable'/>
      <div className='leaderboard-description'><span>*Activity Point System: </span>Activity points (AP) are awarded to users for performing activities in the ecosystem. Various activities and their APs are as follows:
        <div className='leaderboard-description'>Watch Ad for watering can: 2AP</div>
        <div className='leaderboard-description'>Water garden: 2AP</div>
        <div className='leaderboard-description'>Watch Ad for fertilizer: 2AP</div>
        <div className='leaderboard-description'>Fertilize garden: 2AP</div>
        <div className='leaderboard-description'>Start mining: 1AP</div>
        <div className='leaderboard-description'>Claim mining: 2AP</div>
        <div className='leaderboard-description'>Plant: 5AP</div>
        <div className='leaderboard-description'>Harvest: 5AP</div>
        <div className='leaderboard-description'>Battle (any mode): 1AP</div>
        <div className='leaderboard-description'>Watch Ad for elixir: 2AP</div>
        <div className='leaderboard-description'>Use elixir: 1AP</div>
        <h2 className='leaderboard-subheadings'>LEAGUE REQUIREMENTS</h2>
        <img src={table4} alt='leaguetable'/>
      </div>
      <div className='leaderboard-description'><span>*Referral Point System: </span>Referral points (RP) are awarded to the user when their referral is verified. Verification occurs when the referred user attains level 2. The referrer is then awarded RP as their referrals progress as follows:
        <div className='leaderboard-description'>Lvl 2: 1RP</div>
        <div className='leaderboard-description'>Lvl 4: 2RP</div>
        <h2 className='leaderboard-subheadings'>LEAGUE REQUIREMENTS</h2>
        <img src={table1} alt='leaguetable'/>
      </div>
      <div className='leaderboard-description'><span>*VYNE Battle System: </span>Vyne battle points (VBP) are awarded based on wins and losses in duels between users. Duels are fought only when a player challenges another. A snapshot/screenshot at the end of the match showing the winner and the loser are considered valid and used to award VBP. It should be of note that the VBP system is a fluid point system.
        <div className='leaderboard-description'>Win battle: 3VBP</div>
        <div className='leaderboard-description'>Lose battle: -1VBP</div>
        <div className='leaderboard-description'>Draw battle: 1VBP </div>
        <span className='critical'>NB: ONLY THE FIRST 10 ENTRY OF THE DAY WILL BE RECORDED ON THE LEADERBOARD</span>
        <h2 className='leaderboard-subheadings'>LEAGUE REQUIREMENTS</h2>
        <img src={table2} alt='leaguetable'/>
      </div>
      <div className='leaderboard-description'><span>*USDT Battle System: </span>Vyne battle points (VBP) are awarded based on wins and losses in duels between users. Duels are fought only when a player challenges another. A snapshot/screenshot at the end of the match showing the winner and the loser are considered valid and used to award VBP. It should be of note that the VBP system is a fluid point system.
        <div className='leaderboard-description'>Win USDT Duel: 5BP</div>
        <div className='leaderboard-description'>Lose USDT Duel: -2BP</div>
        <div className='leaderboard-description'>Draw USDT Duel: 2BP </div>
        <span className='critical'>NB: ONLY THE FIRST 10 ENTRY OF THE DAY WILL BE RECORDED ON THE LEADERBOARD</span>
        <h2 className='leaderboard-subheadings'>LEAGUE REQUIREMENTS</h2>
        <img src={table3} alt='leaguetable'/>
      </div>
    </div>
  )
}

export default Leaderboard