import React from "react"
import { useState } from "react"
import "../styles/Order.css"

function WoodOrder({open, onClose, wood}) {
    const [amount, setAmout] = useState('')
        if(!open) return null    
    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <div className="order-modal">
                    <p onClick={onClose} className="close-btn">X</p>
                    <div className="content">
                        <div className="lister-container">
                            <p>{wood.created_by.toUpperCase()}</p>
                            <p>Transactions:<span>+10</span></p>
                        </div>
                        <div className="order-info">
                            <h1 className="info-active">Order Info</h1>
                            <div className="price-container">
                                <p>Price($):</p>
                                <p>{wood.price}</p>
                            </div>
                            <div className="quantity-container">
                                <p>Available:</p>
                                <p>{wood.quantity}</p>
                            </div>
                            <div className="balance-container">
                                <p>Balance:</p>
                                <p>VyNE</p>
                            </div>
                            <div className="amount-container">
                                <input
                                    className="form-input"
                                    type="number"
                                    required
                                    value={amount}
                                    onChange={(e) => setAmout(e.target.value)}
                                    placeholder="Amount"
                                />
                                <button>All</button>
                            </div>
                            <div className="btn-container">
                                <button onClick={onClose} className="cancel-btn">Cancel</button>
                                <button className="buy-btn">BUY</button>
                            </div>
                        </div>
                    </div>

            </div>
            </div>
        </div>
    )
}

export default WoodOrder