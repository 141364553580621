import React from "react"
import "../styles/Marketplace.css"
import { ACCESS_TOKEN } from "../constants"
import { jwtDecode } from "jwt-decode"

function Marketplace() {
    const token = localStorage.getItem(ACCESS_TOKEN)

    if (token) {
      const user = jwtDecode(token)
      const username = user.username
  }
    return (
        <div className="market-page">
            <div>
                <div className="marketplace-container">
                    <p className="heading">Midas P2P Marketplace</p>
                    <div className="resources-container">
                        <div className="resources">
                            <a className="wood" href="/marketplace/woods">WOOD</a>
                            <a className="steel" href="/marketplace/steel">STEEL</a>
                        </div>
                        <div className="resources">
                            <a className="ropes" href="/marketplace/ropes">ROPES</a>
                            <a className="cotton" href="/marketplace/cotton">COTTON</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marketplace
