import { useState } from "react"
import api from "../components/api"
import { useNavigate } from "react-router-dom"
import "../styles/Form.css"
import FormInput from "../components/FormInput"

const swal = require('sweetalert2')


function Register() {
    const [loading, setLoadng] = useState(false)
    const [errors, setErrors] = useState("")
    const [values, setValues] = useState({
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        country: "",
        phone_number: "",
        date_of_birth: "",
        password: "",
        confirmPassword: "",
        reffered_by: "",
    });

    const inputs = [
        {
            id: 1,
            name: "username",
            type: "text",
            placeholder: "Username",
            errorMessage: "Username should be 5-16 characters without any special character!!",
            label: "Username",
            pattern: "^[A-Za-z0-9]{5,16}$",
            required: true
        },
        {
            id: 2,
            name: "email",
            type: "email",
            placeholder: "Email",
            errorMessage: "This should be a valid email address to receive OTP on sign-up!",
            label: "Email",
            required: true
        },
        {
            id: 3,
            name: "first_name",
            type: "text",
            placeholder: "First Name",
            errorMessage: "",
            label: "First Name",
            required: true
        },
        {
            id: 4,
            name: "last_name",
            type: "text",
            placeholder: "Last Name",
            errorMessage: "",
            label: "Last Name",
            required: true
        },
        {
            id: 5,
            name: "country",
            type: "text",
            placeholder: "Country",
            errorMessage: "",
            label: "Country",
            required: true
        },
        {
            id: 6,
            name: "phone_number",
            type: "text",
            placeholder: "Phone Number",
            errorMessage: "",
            label: "Phone Number",
            required: true
        },
        {
            id: 7,
            name: "date_of_birth",
            type: "date",
            placeholder: "Date Of Birth",
            errorMessage: "",
            label: "Date Of Birth",
            required: true
        },
        {
            id: 8,
            name: "password",
            type: "password",
            placeholder: "Password",
            errorMessage: "Password should be 8-20 characters with atleast 1 number and 1 special character!!",
            label: "Password",
            pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
            required: true
        },
        {
            id: 9,
            name: "confirmPassword",
            type: "password",
            placeholder: "Confirm Password",
            errorMessage: "Passwords don't match!!!",
            label: "Confirm Password",
            pattern: values.password,
            required: true
        },
        {
            id: 10,
            name: "reffered_by",
            type: "text",
            placeholder: "Refferal Code",
            errorMessage: "",
            label: "Refferal Code",
        },

    ]
    //    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        //        setLoading(true);
        e.preventDefault();
        const data = new FormData(e.target);
        console.log(data.get('username'))

            try {
                const response = await api.post("/v1/register/", [data.get('username'), data.get('email'), data.get('first_name'), data.get('last_name'), data.get('country'), data.get('phone_number'), data.get('password'), data.get('reffered_by')])
                if (response) {
                    console.log(response.data)
                    swal.fire({
                        title: "Registration Success",
                        text: `Check ${response.data.email} for account activation link`,
                        icon: "success",
                        toast: true,
                        timer: 4000,
                        position: 'center',
                        timerProgressBar: true,
                        showConfirmButton: false,
                    })
                    navigate("/login")
                }
                else {
                    navigate("/register")
                }

                return response
            } catch (errors) {
                console.log(errors.response.data);
            } finally {
                // setLoading(false)
            }
    };

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    return (
        <div className="form-page-container">
            <form onSubmit={handleSubmit}>
                <h1>Register</h1>
                {inputs.map((input) => (
                    <FormInput
                        key={input.id}
                        {...input}
                        value={values[input.name]}
                        onChange={onChange}
                    />
                ))}
                <button>Register</button>
            </form>
        </div>
    )
}

export default Register;