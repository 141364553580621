import React from 'react'
import "../styles/About.css"
import { assets } from '../components/Callables'

const About = () => {
  return (
    <div className='about-page-container'>
      <div className='vynechain-description'>
      VYNECHAIN is a blockchain that will integrate the world of gaming and decentralized finance (Gamefi + DEFI) by combining the interactive engagement of gaming with the financial opportunities offered by DEFI protocols. 
      </div>
      <h2 className='subheadings'>GAMEFI</h2>
      <div className='vynechain-description'>
      This is a process whereby players in a gaming ecosystem can potentially earn cryptocurrencies and other digital assets by participating in games, completing tasks and by contributing to the ecosystem. These assets can then be traded, staked, or used within the gaming environment.
      </div>
      <h2 className='subheadings'>KEY FEATURES</h2>
      <div className='writing-container'>
        <div className='vynechain-description'>
          . Play-to-earn (P2E): With this feature, players earn rewards in the form of cryptocurrency by playing games, completing challenges or by achieving certain milestones.
        </div>
        <div className='vynechain-description'>
          . NFT Integration: NFTs are used to represent assets such as characters, items or real estate. NFTs can be traded or utilized across the gaming platform as the players wish. 
        </div>
        <div className='vynechain-description'>
          . DEFI Mechanism: Incorporating DEFI protocols such as liquidity pools, yield farming and governance tokens provide additional incentives and are also of varying functions in the ecosystem.
        </div>
        <div className='vynechain-description'>
          . Community and Engagement: Gamefi typically relies on the strong communities of players and investors as they are involved in shaping the development, government and economy of the gaming ecosystem.
        </div> 
      </div>
      <h2 className='subheadings'>PROBLEMS AND OUR SOLUTIONS</h2>
      <div className='writing-container'>
        <div className='vynechain-description'>
          1. Network Congestion: Gamefi projects can become congested and often face scalability issues often leading to high transaction fees.
        </div>
        <div className='solution'>
          SOLUTION: vynechain will be operating on its very own blockchain with a very low transaction fee of 0.01%.
        </div>
        <div className='vynechain-description'>
          2. User Inexperience: Many gamefi platforms require users to have a good understanding of blockchain technology, wallets and cryptocurrencies which can be a barrier to entry for mainstream gamers.
        </div>
        <div className='solution'>
          SOLUTION: Vyne Academy will be put in place to educate users on blockchain technology. Tests will be issued and medals will be awarded as users advance in the academy.
        </div>
        <div className='vynechain-description'>
          3. Market Saturation and Competition: Gamefi market is becoming increasingly more crowded, making it difficult for new projects to stand out and gain traction.
        </div>
        <div className='solution'>
          SOLUTION: Vynechain has a planned stream of continuous innovations as well as collaborations with other developers that will promote the ecosystem and keep it compelling.
        </div> 
        <div className='vynechain-description'>
          4. Security Risk: Smart contract vulnerabilities, hacking incidents and scams are prevalent in the blockchain space posing risks to both the developers and the users.
        </div>
        <div className='solution'>
          SOLUTION: Vynechain is employing the best cyber security team as well as implementing rigid security measures to safe guard the network for both the team and the users' interests.
        </div>
      </div>
      <div className='assets-container'>
        <div className='assets-title'>Assets</div>
        <div className='assets-desc'>Here is a list of our assets</div>
        <div className='asset-container'>
        {assets.map((asset) => (
          <div className='asset-card'>
            <div className='face face1'>
              <div className='asset-content'>
                <h2>{asset.name}</h2>
                <p>{asset.description}</p>
              </div>
            </div>
            <div className='face face2'>
              <img src={asset.image} alt='vyneappimage'/>
            </div>
          </div>
          ))}
        </div>
      </div>
    </div>
    
  )
}

export default About